<template>
    <teleport to="#header-action">
      <Btn v-if="helperService.userHasPermission('essairapport_oadd')"
        class="action-btn"
        icon="plus"
        color="primary"
        text="Ajouter un rapport statistiques"
        :to="{
          name: 'essaiChildFixedReportAddView',
          params: {
            id: $route.params.id
          }
        }" />
    </teleport>
    <List :api="list.api"
      :header="list.headers"
      :items="list.col"
      :item-key="list.key">
      <template v-slot:group-actions="{ selectedItems }">
        <Btn v-if="helperService.userHasPermission('essairapport_oremove')"
          text="Supprimer"
          icon="trash-alt"
          color="default"
          @click="openModal('deleteItems', selectedItems)" />
      </template>

      <template v-slot:date_generation_debut="{ item }">
        {{ helperService.displayDate(item.date_generation_debut) }}
        {{ helperService.displayTime(item.date_generation_debut) }}
      </template>

      <template v-slot:finalise="{ item }">

        <Tag v-if="item.finalise" text="Disponible" color="success" />
        <Tag v-if="!item.finalise && !item.erreur" text="En cours" color="warning" />
        <Tag v-if="!item.finalise && item.erreur" text="En erreur" color="error" />
        <!-- <span v-if="item.erreur && item.finalise" class="color-error"> {{ item.finalise }}</span> -->
      </template>

      <template v-slot:detail="{ item }">
      {{item.details_erreur}}
    </template>

      <template v-slot:actions="{ item }">
        <ToolDropdown>
          <MenuItem v-if="
            item.media &&
            helperService.userHasPermission('essairapport_oshow')
          "
            :item-data="{
              label: 'Télécharger',
              iconName: 'download'
            }"
            @click="downloadFile(item)" />
          <MenuItem v-if="helperService.userHasPermission('essairapport_oremove')"
            :item-data="{
              label: 'Supprimer',
              iconName: 'trash-alt',
            }"
            @click="openModal('deleteFixedReport', item)" />
          <MenuItem v-if="helperService.userHasPermission('essairapport_oedit')"
            :item-data="{
              label: `Modifier l'intitulé`,
              iconName: 'create',
            }"
            @click="openModal('editFixedReport', item)" />
          <MenuItem v-if="helperService.userHasPermission('essairapport_oedit')"
            :item-data="{
              label: `Relancer l'analyse`,
              iconName: 'pie-chart',
            }"
            @click="openModal('reviveFixedReport', item)" />
        </ToolDropdown>

      </template>
    </List>

    <!-- Modals -->

    <!-- Ajouter un fichier-->
    <Modal :key="componentKey"
      title="Modification de l'intitulé du rapport"
      :active="modal.editFixedReport"
      :data="modalData"
      :containForm="true"
      :modalValidationSchema="validationSchema"
      @modal-close="modal.editFixedReport = false"
      @modal-form-submitted="handleSubmit($event, modalData.id)">
      <template v-slot:modal-body>
        <Input id="designation"
          label="Nom du rapport" />
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler"
          @click="modal.editFixedReport = false" />
        <Btn btnType="submit"
          text="Modifier"
          color="primary" />
      </template>
    </Modal>

    <!-- Delete file -->
    <Modal title="Relancer l'analyse&nbsp;?"
      :active="modal.reviveFixedReport"
      :data="modalData"
      @modal-close="modal.reviveFixedReport = false">
      <template v-slot:modal-body>
        <p>
          Voulez vous vraiment relancer l'analyse statistiques&nbsp;?
        </p>
      </template>
      <template v-slot:modal-footer="{ data }">
        <Btn text="Annuler"
          @click="modal.reviveFixedReport = false" />
        <Btn text="Relancer"
          @click="revive(data)"
          icon="pie-chart"
          color="primary" />
      </template>
    </Modal>

    <!-- Delete file -->
    <Modal title="Supprimer le rapport&nbsp;?"
      :active="modal.deleteFixedReport"
      :data="modalData"
      @modal-close="modal.deleteFixedReport = false">
      <template v-slot:modal-body>
        <p>
          Voulez vous vraiment supprimer le rapport statistiques&nbsp;?
        </p>
      </template>
      <template v-slot:modal-footer="{ data }">
        <Btn text="Annuler"
          @click="modal.deleteFixedReport = false" />
        <Btn text="Supprimer"
          @click="deleteItem(data)"
          icon="trash-alt"
          color="primary" />
      </template>
    </Modal>

    <!-- Groupe d'actions de suppression -->
    <Modal title="Supprimer des rapport&nbsp;?"
      :active="modal.deleteItems"
      :data="modalData"
      @modal-close="modal.deleteItems = false">
      <template v-slot:modal-body>
        <p>
          Voulez vous vraiment supprimer ces rapports statistiques&nbsp;?
        </p>
      </template>
      <template v-slot:modal-footer="{ data }">
        <Btn text="Annuler"
          @click="modal.deleteItems = false" />
        <Btn text="Supprimer"
          @click="deleteItems(data)"
          icon="trash-alt"
          color="primary" />
      </template>
    </Modal>
  </template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Input from '@/components/form/Input.vue'
import Tag from '@/components/base/Tag.vue'

export default {
  name: 'EssaiFixedReportsView',

  components: {
    Tag,
    Input,
    MenuItem,
    ToolDropdown,
    Modal,
    List,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  data() {
    const validationSchema = this.yup.object({
      designation: this.yup.string().required().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      search: {
        attributs: ['titre'],
        filters: {},
      },

      list: {
        api: {
          endpoint: `essai/${this.$route.params.id}/rapport`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: `utilisateur.entite.id:eq(${this.$store.state.auth.user.data.entite.id})`,
          },
        },
        headers: [
          { label: 'Intitulé' },
          { label: 'Date' },
          { label: 'Etat' },
          { label: '' },
        ],
        col: ['designation', 'date_generation_debut', 'finalise', 'detail'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        protocolChoice: false,
        viewFile: false,
        editFixedReport: false,
        editFixedReportTitle: 'Ajout d\'un fichier',
        reviveFixedReport: false,
      },
      protocol: {},
      method: 'post',
      endpoint: 'fichier',
      componentKey: 0,
      textButton: 'Ajouter',
      validationSchema,
      onSubmit,
      isSubmitting,
      errors,
    }
  },

  emits: ['updateEssais'],

  mounted() {
    this.getEssai()
  },

  methods: {
    revive(data) {
      this.emitter.emit('open-loader')
      this.fetchService.patch(`essai/${this.$route.params.id}/rapport/${data.id}/statistiques`)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le rapport statistiques est en cours de génération.',
          })
          this.modal.reviveFixedReport = false
          this.emitter.emit('close-loader')
          this.emitter.emit('list-refresh')
        }, () => {
          this.modal.reviveFixedReport = false
          this.emitter.emit('close-loader')
          this.emitter.emit('list-refresh')
        })
    },
    setMedia(event) {
      this.file = event
    },

    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.protocol = response.data
      })
    },

    downloadFile(item) {
      this.emitter.emit('open-loader')
      const fileName = `rapport_fixe_${item.id}`
      this.fileService.downloadFile(
        `utilitaire/media/${item.media.jeton}.telecharger`, fileName, 'xls',
      ).then(() => {
        this.emitter.emit('close-loader')
      })
    },

    handleSubmit(values, fixedReportId) {
      this.emitter.emit('open-loader')
      console.log(values)

      this.fetchService.put(`essai/${this.$route.params.id}/rapport/${fixedReportId}`,
        values)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le rapport statistiques a bien été modifié.',
          })
          this.emitter.emit('list-refresh')
        })

      this.modal.editFixedReport = false
      this.emitter.emit('close-loader')
    },

    deleteItem(item) {
      this.emitter.emit('open-loader')

      this.fetchService.delete(`essai/${this.$route.params.id}/rapport/${item.id}`)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le rapport statistiques a bien été supprimé.',
          })
          this.emitter.emit('list-refresh')
        })

      this.modal.deleteFixedReport = false
      this.emitter.emit('close-loader')
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`essai/${this.$route.params.id}/rapport/${item.id}`).then(
          () => {
            this.emitter.emit('list-refresh')
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Les rapports statistiques ont bien été supprimés.',
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.modal.deleteItems = false
      this.emitter.emit('close-loader')
    },
  },
}
</script>

  <style scoped>

  </style>
